









































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import {OutputOrganizationDTO, UpdateOrganizationDTO} from "@/api";
import LocationsList from './LocationsList.vue';
import Loading from "@/components/Loading.vue";

interface FormData {
  name: string|null
}

@Component({
  components: {
    Loading,
    LocationsList,
  }
})
export default class OrganisationsEdit extends Vue {
  public valid = true;
  public formData = {
    name: null
  } as FormData
  public organization: OutputOrganizationDTO|null = null;

  @Prop(Number) readonly organizationId!: number;

  public async mounted() {
    this.organization = await this.$store.dispatch('organizations/get', { organizationId: this.organizationId })
    this.reset();
  }

  public reset() {
    this.$validator.reset();

    if (this.organization) {
      this.formData.name = this.organization.name;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (this.organization === null) {
      return;
    }
    if (await this.$validator.validateAll()) {
      const dto: UpdateOrganizationDTO = {
        name: this.formData.name!
      };

      await this.$store.dispatch('organizations/update', { organizationId: this.organization.id!, data: dto})
      await this.$router.push('/main/admin/organisations');
    }
  }

  get loading() {
    return this.organization === null;
  }
}
