

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class DeleteButton extends Vue {
  @Prop(Number) public itemID: number | undefined;
  @Prop() public deleteFunction;

  public dialog: boolean = false;

  public async deleteItem(id) {
    await this.deleteFunction(id);
    this.dialog = false;

    window.location.reload();
  }
}
