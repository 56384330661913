var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Manage Locations")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":{
          name: 'main-admin-locations-create',
          params: {
            organisationId: this.$router.currentRoute.params.organisationId,
          },
        }}},[_vm._v("Create Location")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.locations || [],"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{
            name: 'main-admin-locations-edit',
            params: {
              organisationId: item.organisation_id,
              locationId: item.id,
            },
          }}},[_c('v-icon',[_vm._v("edit")])],1),_c('DeleteButton',{attrs:{"itemID":item.id,"deleteFunction":_vm.deleteLocation}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }