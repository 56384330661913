







































import {Component, Prop, Vue} from 'vue-property-decorator';
import DeleteButton from '@/components/DeleteButton.vue';
import { EventBus } from '@/main';

@Component({
  components: {
    DeleteButton,
  },
})
export default class LocationsList extends Vue {
  @Prop(Number) readonly organizationId;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Latitude',
      sortable: true,
      value: 'latitude',
      align: 'right',
    },
    {
      text: 'Longitude',
      sortable: true,
      value: 'longitude',
      align: 'right',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];

  public locations: Location[]|null = null;

  public async created() {
    EventBus.$on('sse-location', () => {
      this.updateLocations();
    });
  }

  public async mounted() {
    this.updateLocations();
  }

  public async updateLocations() {
    this.locations = await this.$store.dispatch('locations/getByOrganization', { organizationId: this.organizationId })
  }

  public async deleteLocation(id) {
    await this.$store.dispatch('locations/remove', { locationId: id })
  }

  get loading() {
    return this.locations === null;
  }
}
