<template>
  <div class="center-content my-5">
    <v-progress-circular
        color="primary"
        indeterminate
    />
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>
.center-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
